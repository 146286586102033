import { array, boolean, number, object, string } from "yup";
import { dateMax, integerOrDefault, stringMax } from "./utils";

export class PropertyStatus {
  static Actif = new PropertyStatus(0);
  static Archive = new PropertyStatus(1);

  constructor(status) {
    this.status = status;
  }
}

export const DETAIL_CHARGES = ["Taxe foncière", "Eau", "Electricité"];

export const propertyHeadSchema = object({
  nom: stringMax(250).required("Le nom est obligatoire"),
  id_profile_nom_owner: number().required(
    "Vous devez selectionner un profil bailleur",
  ),
  rue: stringMax(250).required("L'adresse est obligatoire"),
  complement_adresse: stringMax(250).optional(),
  ville: stringMax(250).required("La ville est obligatoire"),
  cp: stringMax(10).required("Le code postal est obligatoire"),
  status: number().optional(),
});

export const propertyEquipementsLocalSchema = object({
  chauffage: stringMax(250).optional(),
  eau_chaude: stringMax(250).optional(),
  iban: stringMax(250).optional(),
  surface_total: number().when(["type"], {
    is: (type) => type !== "garage",
    then: number().required("La surface est obligatoire"),
    otherwise: number().optional(),
  }),
  nb_pieces_principales: number().when(["type"], {
    is: (type) => type !== "garage",
    then: number().required(
      "Le nombre de pièces principales du logement est obligatoire",
    ),
    otherwise: number().optional(),
  }),
  autres_pieces: array().of(string()),
  equipements: array().of(string()),
  technologies: array().of(string()),
  locaux_privatifs: array().of(string()),
  locaux_communs: array().of(string()),
  logement_espaces_privatifs: array().of(string()),
  logement_espaces_communs: array().of(string()),
  depenses_energetiques_min: number().optional(),
  depenses_energetiques_max: number().optional(),
  type_propriete: stringMax(250).optional(),
  type_habitat: stringMax(250).optional(),
  year_built: string().when(["type"], {
    is: (type) => type === "habitation",
    then: string().required("L'année de construction est obligatoire"),
    otherwise: string().optional(),
  }),
  date_achat: dateMax().optional(),
  description: string().when(["type"], {
    is: (type) => type === "commercial",
    then: string().required("Une description détaillée est obligatoire"),
    otherwise: string().optional(),
  }),
  description_public: string().optional(),
  article_specific: string().optional(),
  autres_remarques: stringMax(250).optional(),
  identifiant_fiscal: stringMax(250).optional(),
  activite_exerce: stringMax(250).optional(),
  compteur_gaz: stringMax(250).optional(),
  compteur_elec: stringMax(250).optional(),
  compteur_eau: stringMax(250).optional(),
  indice_dpe: stringMax(250).optional(),
});
export const propertyFinanceSchema = object({
  type_charge: stringMax(250).required("Le type de charge est obligatoire"),
  iban: stringMax(250).optional(),
  id_lease_template: number().required("Le type de bail est obligatoire"),
  avantage_fiscal: stringMax(250).optional(),
  loyer_hc: number().required(
    "Le montant du loyer hors charge est obligatoire",
  ),
  charges: number().required("Le montant des charges est obligatoire"),
  deposit: number().required("Le montant du dépôt de garantie est obligatoire"),
  day_payment: integerOrDefault(5),
  zone_tendue: string(),
  complement_loyer: number().optional(),
  justif_complement_loyer: string().optional(),
  dernier_loyer: number().optional(),
  loyer_reference: stringMax(250),
  montant_loyer_ref: number().when(["loyer_reference"], {
    is: (loyer_reference) => loyer_reference === "true",
    then: number().required("Le loyer de référence est obligatoire"),
    otherwise: number().optional(),
  }),
  montant_loyer_ref_maj: number().when(["loyer_reference"], {
    is: (loyer_reference) => loyer_reference === "true",
    then: number().required("Le loyer de référence majoré est obligatoire"),
    otherwise: number().optional(),
  }),
  tva: boolean().optional(),
  type_indice: stringMax(250).optional(),
  detail_charges: array().of(string()).optional(),
});
export const propertyDocumentSchema = object({
  indice_dpe: stringMax(250).optional(),
  pollution: stringMax(250),
  date_dpe: dateMax().optional(),
  date_plomb: dateMax().optional(),
  date_amiante: dateMax().optional(),
  date_pollution: dateMax().optional(),
  date_elec: dateMax().optional(),
  date_gaz: dateMax().optional(),
});
export const propertySchema = propertyEquipementsLocalSchema
  .concat(propertyHeadSchema)
  .concat(propertyFinanceSchema)
  .concat(propertyDocumentSchema)
  .concat(
    object({
      type: stringMax(250).required("Le type de bien est obligatoire"),
      id_parent: number().optional(),
      photo1: string().optional(),
      photos: string().optional(),
      prix_achat: number().optional(),
      is_coloc: string().optional(),
    }),
  );
export const folderSchema = object({
  type: stringMax(250).required("Le type de bien est obligatoire"),
  nom: stringMax(250).required("Un nom de dossier est obligatoire"),
  id_profile_nom_owner: number().required(
    "Vous devez selectionner un profil bailleur",
  ),
});

export const PROPERTY_INITIAL_VALUES = {
  nom: "",
  id_profile_nom_owner: "",
  rue: "",
  complement_adresse: "",
  ville: "",
  cp: "",
  date_achat: "",
  type: "",
  type_charge: "provision",
  id_parent: "",
  description: "",
  description_public: "",
  photo1: "",
  photos: "",
  loyer_hc: "",
  charges: "",
  deposit: "",
  surface_total: "",
  id_lease_template: "",
  year_built: "",
  type_propriete: "copropriete",
  avantage_fiscal: "",
  type_habitat: "",
  chauffage: "individuel",
  eau_chaude: "individuel",
  locaux_privatifs: [],
  locaux_communs: [],
  logement_espaces_privatifs: [],
  logement_espaces_communs: [],
  technologies: [],
  autres_remarques: "",
  depenses_energetiques_min: "",
  depenses_energetiques_max: "",
  autres_pieces: [],
  equipements: [],
  nb_coloc: "",
  day_payment: "5",
  nb_pieces_principales: "",
  prix_achat: "",
  indice_dpe: "",
  pollution: "",
  article_specific: "",
  date_dpe: "",
  date_plomb: "",
  date_amiante: "",
  date_pollution: "",
  date_elec: "",
  date_gaz: "",
  status: PropertyStatus.Actif.status.toString(),
  is_coloc: false,
  zone_tendue: "false",
  complement_loyer: "",
  justif_complement_loyer: "",
  dernier_loyer: "",
  loyer_reference: "false",
  montant_loyer_ref: "",
  montant_loyer_ref_maj: "",
  iban: "",
  identifiant_fiscal: "",
  files: [],
  tva: false,
  type_indice: "Aucun",
  detail_charges: [],
  activite_exerce: "",
  compteur_gaz: "",
  compteur_elec: "",
  compteur_eau: "",
};

export const PROPERTY_FOLDER_INITIAL_VALUES = {
  nom: "",
  id_profile_nom_owner: "",
};
