import { useField } from "formik";

import useDependentField, {
  usePropertyExtractor,
} from "../../hooks/use-dependent-field";
import SelectStandalone, { ReactSelectStandalone } from "./SelectStandalone";

export default function Select({ className = "marginT10", ...props }) {
  const [field, meta] = useField(props);

  useDependentField(
    props.name,
    props.dependon,
    usePropertyExtractor(props.name),
  );

  return (
    <SelectStandalone
      className={className}
      isInvalid={meta.error && meta.touched && !props.disabled}
      error={meta.error}
      {...field}
      {...props}
    />
  );
}

export function ReactSelect(props) {
  const [
    { value, onChange, onBlur, ...field },
    meta,
    { setValue, setTouched },
  ] = useField(props);

  useDependentField(
    props.name,
    props.dependon,
    usePropertyExtractor(props.name),
  );

  return (
    <ReactSelectStandalone
      isClearable
      isInvalid={meta.error && meta.touched && !props.disabled}
      error={meta.error}
      value={props.options.find((option) => option.value.toString() === value)}
      onChange={(option) => setValue(option?.value.toString() ?? "")}
      onBlur={(e) => {
        onBlur(e);
        return setTouched(true);
      }}
      {...field}
      {...props}
    />
  );
}
