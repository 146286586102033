import { array, boolean, number, object, string } from "yup";
import { dateMax, integerOrDefault, stringMax, timeSchema } from "./utils";
import { PROFILE_NOM_INITIAL_VALUE } from "./profile";

export class TenantStatus {
  static Actif = new TenantStatus(1);
  static Candidat = new TenantStatus(4);
  static Archive = new TenantStatus(5);
  static CandidatArchive = new TenantStatus(6);

  constructor(status) {
    this.status = status;
  }
}

export const headerTenantSchema = object({
  id_bien: number().required("Un bien doit obligatoirement être selectionné"),
  profile_nom: object({
    nom_profile: string().required("Un nom de profil est obligatoire"),
  }),
});
export const convertTenantBaseSchema = object({
  id_bien: string().required("Vous devez selectionner un bien"),
  id_lease_template: number()
    .integer()
    .required("Vous devez selectionner un modèle de bail"),
  loyer_hc: number().required("Le loyer hors charge est obligatoire"),
  charges: number().required("Le montant des charges est obligatoire"),
  irl_trimestre: number().optional(),
  irl_indice: number().optional(),
  irl_annee: number().optional(),
  type_charge: stringMax(250).optional(),
  dernier_loyer: number().optional(),

  debut: dateMax().required("La date de début du bail est obligatoire"),
  duree_contrat: number().when(["genBail"], {
    is: (genBail) => genBail === "false",
    then: number().integer().required("La durée du contrat est obligatoire"),
    otherwise: integerOrDefault(0),
  }),
  date_signature: dateMax().when(["genBail"], {
    is: (genBail) => genBail === "false",
    then: (schema) =>
      schema.required("La date de signature du bail est obligatoire"),
    otherwise: (schema) => schema,
  }),
  genBail: stringMax(250).required(" "),
  type_envoi: stringMax(250).required(" "),
  payment_recurrence: stringMax(250).required(
    "La récurrence de paiement est obligatoire",
  ),
  detail_charges: array().of(string()),
});

export const tenantLeaseFormSchema = object({
  loyer_hc: number().required("Le loyer hors charge est obligatoire"),
  charges: number().required("Le montant des charges est obligatoire"),
  deposit: number().when(["lease_type"], {
    is: (lease_type) => lease_type !== "mobilite",
    then: number().required("Le montant du dépôt de garantie est obligatoire"),
    otherwise: number().optional(),
  }),
  preavis: boolean().optional(),
  lease_type: stringMax(250).optional(),
  irl_trimestre: number().optional(),
  irl_indice: number().optional(),
  irl_annee: number().optional(),
  dernier_loyer: number().optional(),
  payment_recurrence: stringMax(250).required(
    "La récurrence de paiement est obligatoire",
  ),
  day_payment: number()
    .required("Le jour de paiement du loyer est obligatoire")
    .min(1, "Le jour de paiement du loyer doit être supérieur à 1")
    .max(28, "Le jour de paiement du loyer doit être inférieur à 28"),
  debut: dateMax().required("La date de début du bail est obligatoire"),
  fin: dateMax().required("La date de fin du bail est obligatoire"),
  date_signature: dateMax().required("La date de signature est requise"),
  last_revision_date: dateMax().optional(),
  article_specific: string().optional(),
});

export const convertTenantSchema = convertTenantBaseSchema.concat(
  object({
    deposit: number().required(
      "Le montant du dépôt de garantie est obligatoire",
    ),
    day_payment: number()
      .required("Le jour de paiement du loyer est obligatoire")
      .min(1, "Le jour de paiement du loyer doit être supérieur à 1")
      .max(28, "Le jour de paiement du loyer doit être inférieur à 28"),
    date_dernier_loyer: dateMax().optional(),
    nature_dernier_travaux: string().optional(),
    montant_dernier_travaux: number().optional(),
  }),
);
export const convertTenantMobiliteSchema = convertTenantBaseSchema.concat(
  object({
    raison_mobilite: stringMax(250).required(" "),
    day_payment: number()
      .required("Le jour de paiement du loyer est obligatoire")
      .min(1, "Le jour de paiement du loyer doit être supérieur à 1")
      .max(28, "Le jour de paiement du loyer doit être inférieur à 28"),
  }),
);
export const convertTenantCivilSchema = convertTenantBaseSchema.concat(
  object({
    preavis_bailleur: number().when("genBail", {
      is: "true",
      then: number()
        .required("Le préavis bailleur est obligatoire")
        .min(1, "Le nombre doit être supérieur à 0"),
      otherwise: number().notRequired(),
    }),
    preavis_locataire: number().when("genBail", {
      is: "true",
      then: number()
        .required("Le préavis locataire est obligatoire")
        .min(1, "Le nombre doit être supérieur à 0"),
      otherwise: number().notRequired(),
    }),
    echeance_bailleur: stringMax(250).required(" "),
    echeance_locataire: stringMax(250).required(" "),
    day_payment: number()
      .required("Le jour de paiement du loyer est obligatoire")
      .min(1, "Le jour de paiement du loyer doit être supérieur à 1")
      .max(28, "Le jour de paiement du loyer doit être inférieur à 28"),
  }),
);
export const convertTenantLCDSchema = convertTenantBaseSchema.concat(
  object({
    acompte: stringMax(250).required(" "),
    date_paiement_solde: dateMax().when("acompte", {
      is: "false",
      then: dateMax().required("La date de paiement du solde est obligatoire"),
      otherwise: dateMax().notRequired(),
    }),
    pourcentage_acompte: string().when("acompte", {
      is: "false",
      then: string().notRequired(),
      otherwise: string().required("Pourcentage acompte est obligatoire"),
    }),
    type_acompte: string().when("acompte", {
      is: "false",
      then: string().notRequired(),
      otherwise: string().required("Type acompte est obligatoire"),
    }),
    condition_paiement_solde: string().when("type_acompte", {
      is: "condition_specifique",
      then: string().required(
        "La condition spécifique de paiement est obligatoire",
      ),
      otherwise: string().notRequired(),
    }),
    date_limite_paiement_solde: dateMax().when("type_acompte", {
      is: "date_limite",
      then: dateMax().required(
        "La date limite de paiement du solde est obligatoire",
      ),
      otherwise: dateMax().notRequired(),
    }),
    taxe_sejour: number().required("La taxe de séjour est obligatoire"),
    nombre_personne: number().required(
      "Le nombre de personne lors de ce séjour est obligatoire",
    ),
    montant_surcharge: number().required(
      "Le montant de la surcharge par personne est obligatoire",
    ),
    fin: dateMax().required("La date de fin du bail est obligatoire"),
    hour_start: timeSchema().required(
      "L'heure de début du bail est obligatoire",
    ),
    hour_end: timeSchema().required("L'heure de fin du bail est obligatoire"),
  }),
);
export const TENANT_INITIAL_VALUE = {
  id_bien: "",
  id_profile: "",
  loyer_hc: "",
  dernier_loyer: "",
  charges: "",
  deposit: "",
  status: "1",
  day_payment: "1",
  debut: "",
  fin: "",
  irl_indice: "",
  irl_trimestre: "",
  irl_annee: "",
  type_charge: "provision",
  preavis: false,
  lease_type: "",
  last_revision_date: "",
  type_envoi: "elec",
  date_signature: "",
  payment_recurrence: "1",
  notes: "",
  article_specific: "",
  acompte: "false",
  date_paiement_solde: "",
  pourcentage_acompte: "",
  type_acompte: "jour_entree",
  condition_paiement_solde: "",
  taxe_sejour: "",
  nombre_personne: "",
  montant_surcharge: "",
  hour_start: "",
  hour_end: "",
  date_dernier_loyer: "",
  nature_dernier_travaux: "",
  montant_dernier_travaux: "",
  join_notice: true,
};

export const ADD_TENANT_INITIAL_VALUE = {
  id_bien: "",
  id_lease_template: "",
  loyer_hc: "",
  dernier_loyer: "",
  charges: "",
  deposit: "",
  status: "1",
  day_payment: "1",
  debut: "",
  fin: "",
  duree_contrat: "0",
  date_signature: "",
  irl_indice: "",
  irl_trimestre: "",
  irl_annee: "",
  type_charge: "provision",
  type_envoi: "elec",
  raison_mobilite: "",
  profile_nom: PROFILE_NOM_INITIAL_VALUE,
  payment_recurrence: "1",
  remuneration_type: "",
  remuneration_montant: "",
  detail_charges: [],
  preavis_locataire: "",
  echeance_locataire: "anytime",
  preavis_bailleur: "",
  echeance_bailleur: "anytime",
  is_meuble: false,
  notes: "",
  article_specific: "",
  acompte: "false",
  date_paiement_solde: "",
  pourcentage_acompte: "",
  type_acompte: "jour_entree",
  condition_paiement_solde: "",
  taxe_sejour: "",
  nombre_personne: "",
  montant_surcharge: "",
  hour_start: "",
  hour_end: "",
  date_dernier_loyer: "",
  nature_dernier_travaux: "",
  montant_dernier_travaux: "",
  join_notice: true,
};
