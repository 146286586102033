import FormikSubmitButton from "./FormikSubmitButton";
import ElectronicSigning from "../ElectronicSigning/ElectronicSigning";
import RadioGroup from "./RadioGroup";
import Radio from "./Radio";
import Card from "./Card";
import { SIGNATURE_PRICE } from "../../models/signatureElectronique";
import useAccount from "../../hooks/use-account";
import Button from "./Button";
import Tabs from "./Tabs";
import { ProfileStatus } from "../../models/profile";
import { GenerateOnly, SendMail, SignatureElec } from "./Icons";

export default function ChooseSendFileMethod({
  values,
  propertyId,
  error,
  profiles,
  caution,
}) {
  const { account } = useAccount();
  const { selectPreviousTab } = Tabs.useActiveTab();
  return (
    <>
      <RadioGroup styling="button" className={"marginT10 flex wrap gap10"}>
        <Radio
          name="type_envoi"
          value="elec"
          label={
            <>
              <SignatureElec />
              <p className={"primaryBlue"}>Signature électronique</p>
            </>
          }
        />
        <Radio
          name="type_envoi"
          value="mail"
          label={
            <>
              <SendMail />
              <p className={"primaryBlue"}>Envoyer par mail</p>
            </>
          }
        />
        <Radio
          name="type_envoi"
          value="generer"
          label={
            <>
              <GenerateOnly />
              <p className={"primaryBlue"}>Créer sans envoi</p>
            </>
          }
        />
      </RadioGroup>
      <Card>
        {values.type_envoi === "elec" && (
          <ElectronicSigning
            tenantProfiles={
              caution
                ? profiles
                : profiles?.filter(
                    (profile) =>
                      profile.garantor_of === null &&
                      profile.status === ProfileStatus.Actif.status,
                  )
            }
            propertyId={propertyId}
            caution={caution}
          />
        )}
        {values.type_envoi === "mail" && (
          <>
            <p className={"secondaryText marginT10"}>
              Votre document sera disponible dans l'onglet "Documents" de votre
              locataire.
            </p>
            <p className={"secondaryText marginT10"}>
              Il sera également envoyé par mail à votre locataire, vous serez en
              copie du mail.
            </p>
          </>
        )}
        {values.type_envoi === "generer" && (
          <p className={"secondaryText marginT10"}>
            Votre document sera disponible dans l'onglet "Documents" de votre
            locataire.
          </p>
        )}
      </Card>
      <div className={"text-right marginT10"}>
        <Button
          type="button"
          onClick={selectPreviousTab}
          className={"marginR10"}
        >
          Précédent
        </Button>
        <FormikSubmitButton
          disabled={
            values.type_envoi === "elec" && account.credits < SIGNATURE_PRICE
          }
        >
          Valider
        </FormikSubmitButton>
      </div>
      {error && (
        <Card type={"error"} className={"padding10 text-center marginT10"}>
          {error}
        </Card>
      )}
    </>
  );
}
