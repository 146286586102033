import {
  Activite,
  Bank,
  CalendarCheck,
  Cctv,
  Charges,
  ConditionsParticulieres,
  DescriptionLogement,
  HousePlus,
  HousePrivatif,
  HouseTree,
  ImmeublePeople,
  Internet,
  Interrogation,
  Surface,
  Temperature,
  Water,
} from "../../UI/Icons";
import { useFormikContext } from "formik";
import TextInput from "../../UI/TextInput";
import Select from "../../UI/Select";
import ExpandableCheckboxList from "../../UI/ExpandableCheckboxList";
import Radio from "../../UI/Radio";
import StyledForm from "../../UI/StyledForm";
import TextArea from "../../UI/TextArea";
import useLeaseTemplates from "../../../hooks/use-lease-templates";
import Tooltip from "../../UI/Tooltip";
import useBanks from "../../../hooks/use-banks";
import Checkbox from "../../UI/Checkbox";

const EQUIPEMENTS = [
  "Interphone",
  "Digicode",
  "Eau froide collective",
  "Gardien",
];

const TECHNOLOGIES = ["Adsl", "Fibre"];

const LOCAUX_PRIVATIFS = [
  "Parking couvert",
  "Parking aerien",
  "Box de stockage",
  "Garage fermé",
  "Jardin privatif",
  "Cour privative",
  "Cave",
  "Borne de recharge électrique",
];

const LOCAUX_COMMUNS = [
  "Ascenseur",
  "Jardin",
  "Espace vert",
  "Cour",
  "Garage à vélo",
];
const LOGEMENT_ESPACES_PRIVATIFS = [
  "Chambre",
  "Placard",
  "Place de parking",
  "Cave",
];
const LOGEMENT_ESPACES_COMMUNS = [
  "Salon",
  "Cuisine",
  "Toilettes",
  "Salle de bain",
  "Buanderie",
];

const AUTRES_PIECES = [
  "Entrée indépendante",
  "Salon",
  "Salon avec cuisine",
  "Salle de bain",
  "WC",
  "Chambre 1",
  "Chambre 2",
  "Chambre 3",
  "Balcon",
  "Terrasse",
  "Cuisine",
  "Pièce de vie unique (studio)",
];
const LOCAL_PIECES = [
  "Espace de vente",
  "Réserve",
  "Bureau",
  "WC",
  "Entrepôt",
  "Local technique",
  "Vestiaire",
];

const yearBuilt = [
  "avant 1949",
  "entre 1949 et 1974",
  "entre 1975 et 1989",
  "entre 1990 et 2005",
  "après 2005",
];

export default function EquipementsLocal({ edit }) {
  const { values, setFieldValue } = useFormikContext();
  const { leaseTemplates } = useLeaseTemplates();
  const { banks } = useBanks();

  function addItem(fieldName, item) {
    if (values[fieldName] === undefined || values[fieldName] === null) {
      setFieldValue(fieldName, [item]);
    } else if (!values[fieldName].includes(item)) {
      setFieldValue(fieldName, [...values[fieldName], item]);
    }
  }

  const equipementsCheckboxItems = [
    ...EQUIPEMENTS,
    ...values.equipements.filter((i) => !EQUIPEMENTS.includes(i)),
  ];
  const technologiesCheckboxItems = [
    ...TECHNOLOGIES,
    ...values.technologies.filter((i) => !TECHNOLOGIES.includes(i)),
  ];
  const locauxPrivatifsCheckboxItems = [
    ...LOCAUX_PRIVATIFS,
    ...values.locaux_privatifs.filter((i) => !LOCAUX_PRIVATIFS.includes(i)),
  ];
  const locauxCommunsCheckboxItems = [
    ...LOCAUX_COMMUNS,
    ...values.locaux_communs.filter((i) => !LOCAUX_COMMUNS.includes(i)),
  ];
  const logementEspacesCommunsCheckboxItems = [
    ...LOGEMENT_ESPACES_COMMUNS,
    ...values.logement_espaces_communs.filter(
      (i) => !LOGEMENT_ESPACES_COMMUNS.includes(i),
    ),
  ];
  const logementEspacesPrivatifsCheckboxItems = [
    ...LOGEMENT_ESPACES_PRIVATIFS,
    ...values.logement_espaces_privatifs.filter(
      (i) => !LOGEMENT_ESPACES_PRIVATIFS.includes(i),
    ),
  ];
  const autresPiecesCheckboxItems = [
    ...AUTRES_PIECES,
    ...values.autres_pieces.filter((i) => !AUTRES_PIECES.includes(i)),
  ];
  const localPiecesCheckboxItems = [
    ...LOCAL_PIECES,
    ...values.autres_pieces.filter((i) => !LOCAL_PIECES.includes(i)),
  ];

  const leaseTemplate = leaseTemplates?.find(
    (lease) => parseInt(values.id_lease_template) === lease.id,
  );

  return (
    <>
      {edit && (
        <>
          <StyledForm
            formElement={
              <Select name="type">
                <option value="habitation">
                  Local à usage d'habitation (Maison, appartement, chambre)
                </option>
                <option value="commercial">Local à usage commercial</option>
                <option value="garage">
                  Autre type de local (garage, parking, etc)
                </option>
              </Select>
            }
            label="Type de local"
            icon={<CalendarCheck stroke={"#9D9D9D"} />}
            mandatory
          />
          {values.type === "habitation" && (
            <>
              <StyledForm
                formElement={
                  <Checkbox name={"is_coloc"}>
                    Ce bien est en colocation
                  </Checkbox>
                }
                label="Mode d'exploitation du logement"
                icon={<HousePlus />}
                mandatory
                className={"marginB20"}
              />
              <StyledForm
                formElement={
                  <div className={"gridColWrap240"}>
                    <Radio
                      name="type_habitat"
                      value="individuel"
                      label="Logement indépendant"
                    />
                    <Radio
                      name="type_habitat"
                      value="collectif"
                      label="Logement dans un immeuble"
                    />
                  </div>
                }
                label="Type d'habitat"
                icon={<HousePlus />}
                mandatory
                className={"marginB20"}
              />
            </>
          )}
        </>
      )}
      {values.type !== "garage" && (
        <StyledForm
          formElement={
            <div className={"flex wrap gap10"}>
              <TextInput
                type="number"
                name="nb_pieces_principales"
                placeholder="3"
                unit={"pièces"}
                className={"flex-grow"}
              />
              <TextInput
                type="number"
                name="surface_total"
                placeholder="45"
                step={"0.01"}
                unit={"m²"}
                className={"flex-grow"}
              />
            </div>
          }
          label="Nombre de pièces et surface totale en m²"
          icon={<Surface />}
          mandatory
          className={"flex-grow"}
        />
      )}
      {values.type === "habitation" && (
        <>
          <StyledForm
            formElement={
              <ExpandableCheckboxList
                name="equipements"
                items={equipementsCheckboxItems}
                addItem={addItem}
                classNameG={"marginT10 marginB20"}
              />
            }
            label="Equipements du logement"
            icon={<Cctv />}
          />
          <StyledForm
            formElement={
              <ExpandableCheckboxList
                name="technologies"
                items={technologiesCheckboxItems}
                addItem={addItem}
                classNameG={"marginT10 marginB20"}
              />
            }
            label="Internet"
            icon={<Internet />}
          />
          <StyledForm
            formElement={
              <div className={"flex wrap gap10"}>
                <TextInput
                  type="number"
                  name="depenses_energetiques_min"
                  placeholder="900"
                  step={"0.01"}
                  unit={"€"}
                  className={"flex-grow"}
                />
                <TextInput
                  type="number"
                  name="depenses_energetiques_max"
                  placeholder="1100"
                  step={"0.01"}
                  unit={"€"}
                  className={"flex-grow"}
                />
              </div>
            }
            label={
              <div>
                Montant annuel des dépenses énergetiques minimum et maximum{" "}
                <Tooltip
                  content={
                    <p>
                      La fourchette de dépense à indiquer figure en page 1 de
                      votre DPE dans la rubrique "Estimation des coûts annuels
                      d'énergie du logement"
                      <img
                        src={"/photo_dpe.png"}
                        alt={"exemple de dpe"}
                        width={250}
                        className={"marginT10"}
                      />
                    </p>
                  }
                >
                  <Interrogation />
                </Tooltip>
              </div>
            }
            icon={<Charges />}
          />
          <StyledForm
            formElement={
              <div className={"gridColWrap240"}>
                <Radio
                  name="type_propriete"
                  value="monopropriete"
                  label="Monopropriete"
                  dependon={"id_parent"}
                />
                <Radio
                  name="type_propriete"
                  value="copropriete"
                  label="Copropriete"
                  dependon={"id_parent"}
                />
              </div>
            }
            label="Type de propriété"
            icon={<ImmeublePeople />}
            mandatory
            className={"marginB20"}
          />
          <StyledForm
            formElement={
              <Select name="year_built" dependon="id_parent">
                <option value="">
                  Selectionnez une période de construction
                </option>
                {yearBuilt.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </Select>
            }
            label="Année de construction"
            icon={<CalendarCheck stroke={"#9D9D9D"} />}
            mandatory
          />
        </>
      )}
      {values.type !== "garage" && (
        <StyledForm
          formElement={
            <ExpandableCheckboxList
              name="autres_pieces"
              items={
                values.type === "habitation"
                  ? autresPiecesCheckboxItems
                  : localPiecesCheckboxItems
              }
              addItem={addItem}
              classNameG={"marginT10 marginB20"}
            />
          }
          label={`Composition du ${
            values.type === "habitation" ? "logement" : "local"
          }`}
          icon={<HousePlus stroke="#707989" />}
        />
      )}

      {values.type_habitat === "collectif" && (
        <>
          <StyledForm
            formElement={
              <ExpandableCheckboxList
                name="locaux_privatifs"
                items={locauxPrivatifsCheckboxItems}
                addItem={addItem}
                classNameG={"marginT10 marginB20"}
              />
            }
            label="Parties de l'IMMEUBLE à usage privatif"
            icon={<HousePrivatif />}
          />
          <StyledForm
            formElement={
              <ExpandableCheckboxList
                name="locaux_communs"
                items={locauxCommunsCheckboxItems}
                addItem={addItem}
                classNameG={"marginT10 marginB20"}
              />
            }
            label="Parties de l'IMMEUBLE à usage commun"
            icon={<HouseTree />}
          />
          <StyledForm
            formElement={
              <div className={"gridColWrap240"}>
                <Radio
                  name="eau_chaude"
                  value="individuel"
                  label="Eau chaude individuelle"
                  dependon={"id_parent"}
                />
                <Radio
                  name="eau_chaude"
                  value="collectif"
                  label="Eau chaude collective"
                  dependon={"id_parent"}
                />
              </div>
            }
            label="Eau chaude"
            icon={<Water />}
            mandatory
            className={"marginB20"}
          />
          <StyledForm
            formElement={
              <div className={"gridColWrap240"}>
                <Radio
                  name="chauffage"
                  value="individuel"
                  label="Chauffage individuel"
                  dependon={"id_parent"}
                />
                <Radio
                  name="chauffage"
                  value="collectif"
                  label="Chauffage collectif"
                  dependon={"id_parent"}
                />
              </div>
            }
            label="Type de chauffage"
            icon={<Temperature />}
            mandatory
            className={"marginB20"}
          />
        </>
      )}
      {values.is_coloc && (
        <>
          <StyledForm
            formElement={
              <ExpandableCheckboxList
                name="logement_espaces_communs"
                items={logementEspacesCommunsCheckboxItems}
                addItem={addItem}
                classNameG={"marginT10 marginB20"}
              />
            }
            label="Si colocation, parties du LOGEMENT à usage commun"
            icon={<HouseTree />}
          />
          <StyledForm
            formElement={
              <ExpandableCheckboxList
                name="logement_espaces_privatifs"
                items={logementEspacesPrivatifsCheckboxItems}
                addItem={addItem}
                classNameG={"marginT10 marginB20"}
              />
            }
            label="Si colocation, parties du LOGEMENT à usage privée"
            icon={<HouseTree />}
          />
        </>
      )}
      {values.type === "commercial" && (
        <StyledForm
          formElement={
            <div>
              <TextInput
                type="text"
                name="activite_exerce"
                placeholder="Activité de boulangerie"
                className={"flex-grow"}
              />
            </div>
          }
          label="Activité exercée"
          icon={<Activite />}
          mandatory
          className={"flex-grow"}
        />
      )}
      <StyledForm
        formElement={
          <TextInput type="date" name="date_achat" dependon="id_parent" />
        }
        label={
          <div>
            Date d'achat{" "}
            <Tooltip
              content={
                "Renseigner cette date permet de ne pas générer les loyers des locataires existants que vous ajouterez, avant la date d'achat."
              }
            >
              <Interrogation />
            </Tooltip>
          </div>
        }
        icon={<CalendarCheck stroke={"#9D9D9D"} />}
      />
      <StyledForm
        formElement={
          <>
            {banks?.length > 0 && (
              <Select
                dependon="id_parent"
                name="select_iban"
                onChange={(e) => {
                  setFieldValue("iban", e.target.value);
                }}
              >
                <option value="">
                  Selectionnez une banque ou entrez l'IBAN
                </option>
                {banks.map((bank) => (
                  <option key={bank.id} value={bank.iban}>
                    {bank.nom ? bank.nom : bank.bank_metadata.name}
                  </option>
                ))}
              </Select>
            )}
            <TextInput type="text" name="iban" dependon="id_parent" />
          </>
        }
        label={
          <div>
            IBAN{" "}
            <Tooltip
              content={
                "L'IBAN sera intégré au bail, et envoyé par mail au locataire lors du premier contact. Veuillez vérifier que l'IBAN est correct."
              }
            >
              <Interrogation />
            </Tooltip>
          </div>
        }
        icon={<Bank stroke={"#9D9D9D"} />}
      />
      {values.type === "habitation" && (
        <StyledForm
          formElement={
            <Select name="indice_dpe" dependon="id_parent" label={"Indice DPE"}>
              <option value={""}>Choisissez un indice</option>
              <option value={"A"}>A</option>
              <option value={"B"}>B</option>
              <option value={"C"}>C</option>
              <option value={"D"}>D</option>
              <option value={"E"}>E</option>
              <option value={"F"}>F</option>
              <option value={"G"}>G</option>
            </Select>
          }
          label={"DPE"}
          icon={<Bank stroke={"#9D9D9D"} />}
        />
      )}
      {leaseTemplate?.identifiant_fiscal && (
        <StyledForm
          formElement={
            <TextInput
              type="text"
              name="identifiant_fiscal"
              dependon="id_parent"
            />
          }
          label={
            <div>
              Identifiant fiscal du logement{" "}
              <Tooltip
                content={
                  "Il s'agit d’une nouvelle mention obligatoire sur les contrats de location depuis le 1er janvier 2024. Vous retrouvez le numéro fiscal de votre logement depuis le site Impot.gouv.fr rubrique “Biens immobiliers” puis en cliquant sur “Consulter” pour le bien concerné. Cette mention obligatoire ne s'applique pas aux baux mobilités, aux colocations, ni aux locaux professionnels."
                }
              >
                <Interrogation />
              </Tooltip>
            </div>
          }
          icon={<Bank stroke={"#9D9D9D"} />}
        />
      )}

      {values.type !== "garage" && (
        <StyledForm
          formElement={
            <div className={"flex gap10 wrap"}>
              <TextInput
                type="text"
                name="compteur_elec"
                dependon="id_parent"
                label={"Électricité"}
              />
              <TextInput
                type="text"
                name="compteur_gaz"
                dependon="id_parent"
                label={"Gaz"}
              />
              <TextInput
                type="text"
                name="compteur_eau"
                dependon="id_parent"
                label={"Eau"}
              />
            </div>
          }
          label={"Numéros de compteur"}
          icon={<Bank stroke={"#9D9D9D"} />}
        />
      )}
      <StyledForm
        formElement={
          <TextArea
            type="text"
            name="description"
            placeholder="Description détaillée intégrée au bail."
          />
        }
        label={
          <div>
            Description détaillée du bien figurant dans le bail{" "}
            <Tooltip content={"Cette description sera intégrée au bail."}>
              <Interrogation />
            </Tooltip>
          </div>
        }
        icon={<DescriptionLogement />}
        mandatory={values.type === "commercial"}
      />
      <StyledForm
        formElement={
          <TextArea
            type="text"
            name="description_public"
            placeholder="Superbe appartement en plein centre de Lyon, vous trouverez ..."
            rows={"5"}
          />
        }
        label={
          <div>
            Description publique du bien{" "}
            <Tooltip
              content={
                "Vous pouvez par exemple mettre ici le contenu de votre annonce. Ce texte sera visible par les candidats lorsqu'ils soumettrons leur dossier."
              }
            >
              <Interrogation />
            </Tooltip>
          </div>
        }
        icon={<DescriptionLogement />}
      />
      <StyledForm
        formElement={
          <TextArea
            type="text"
            name="article_specific"
            placeholder="Autres remarques"
            rows={"5"}
          />
        }
        label="Article spécifique à faire paraître dans le bail"
        icon={<ConditionsParticulieres />}
      />
    </>
  );
}
