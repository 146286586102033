import { classnames } from "../../utils";
import Select from "react-select";
import styles from "./Select.module.css";

export default function SelectStandalone({
  label,
  mandatory,
  isInvalid,
  error,
  className,
  hidden,
  ...props
}) {
  return (
    <div className={classnames(styles.control, className)} hidden={hidden}>
      <label htmlFor={props.id || props.name}>
        {label}
        {mandatory && <span className={"blueStar"}> *</span>}
      </label>
      <div
        className={classnames(
          "margin-form",
          styles.selectWrapper,
          isInvalid && styles.error,
        )}
      >
        <select {...props} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 5"
          fill="#0C86F5"
        >
          <path d="M0 0L5 5L10 0L8 0L5 3L2 0Z" />
        </svg>
      </div>
      {isInvalid ? <div className="error marginT5">{error}</div> : null}
    </div>
  );
}

export function ReactSelectStandalone({
  isInvalid,
  error,
  className,
  placeholder,
  label,
  mandatory,
  hidden,
  ...rest
}) {
  const reactSelectStyles = {
    placeholder: (base) => ({
      ...base,
      color: "darkgray",
    }),
    control: ({ display }, state) => {
      let backgroundColor = "#fff",
        borderColor = "#CED4DE";
      if (state.isFocused) {
        backgroundColor = "#f2f6ff";
        borderColor = "#0C86F5";
      }
      if (isInvalid) {
        backgroundColor = "#f8d7da";
        borderColor = "#842029";
      }

      return {
        display,
        height: "2.5rem",
        backgroundColor,
        border: "1px solid",
        borderRadius: "8px",
        borderColor: borderColor,
      };
    },
  };
  return (
    <div className={className} hidden={hidden}>
      <label htmlFor={rest.id || rest.name}>
        {label}
        {mandatory && <span className={"blueStar"}> *</span>}
      </label>
      <Select
        styles={reactSelectStyles}
        placeholder={placeholder ? `Ex : ${placeholder}` : undefined}
        {...rest}
      />
      {isInvalid && <div className="error marginT5">{error}</div>}
    </div>
  );
}
